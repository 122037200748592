import React, { Component } from "react";
import Header from "../../components/header/header";
import Download from "../../components/download/download";
import ScrollTop from "../../components/scrollTop/scrollTop";
import Footer from "../../components/footer/footer";
import FloatToast from "../../components/float-toast/float-toast";
import about1 from "../../static/images/about/about1.png";
import about2 from "../../static/images/about/about2.png";
import about3 from "../../static/images/about/about3.png";
import about4 from "../../static/images/about/about4.png";
import about5 from "../../static/images/about/about5.png";
import about6 from "../../static/images/about/about6.png";
import about7 from "../../static/images/about/about7.png";
import talentsImg1 from "../../static/images/about/talents_img1.png";
import talentsImg2 from "../../static/images/about/talents_img2.png";
import talentsImg3 from "../../static/images/about/talents_img3.png";
import talentsImg4 from "../../static/images/about/talents_img4.png";
import "./about-me.css";


export default class AboutMe extends Component {
    constructor(props) {
        super(props);
        this.carouselEL= React.createRef();
        this.state = {
          backgroundObj : {
            background: `url(${about6}) `,
            backgroundSize: '100%,100%',
            backgroundRepeat: 'no-repeat'
          },
          title: '曾任职于4家500强IT公司',
          illustrates: [{
            image: about2,
            title: '1988年，将电脑工作站系统提供给航天部'
          },{
            image: about3,
            title: '1990年，将首批银行自助服务机ATM带入中国，是中国IT行业的开创者与先驱'
          },{
            image: about4,
            title: '1999年帮助仅有8㎡的丽妍雅集打造成享誉国内外的高端连锁SPA品牌Rubis SPA'
          },{
            image: about5,
            title: '剑琅管家APP，由剑琅联盟创始人Danny潘家辉先生倾力打造'
          }],
          talentsImgList:[talentsImg1,talentsImg2,talentsImg3,talentsImg4],
          abilitys: [{
            index: 0,
            year: '2021年',
            title: '剑琅联盟 美业人才打造',
            illustrate: '剑琅联盟美业人才打造计划，秉持着“为中华民族栽培美业人才，走向国际舞台”的初衷。',
            content: '邹世欢以及Hyson梅晓峰。其中，邹世欢于2023年去往日本东京imaii学院进修学习；而Hyson则获得剑琅联盟携手沙宣前校长MeePoh罗美宝女士的精心栽培。'
          },{
            index: 0,
            year: '2022年',
            title: '剑琅联盟 美业人才打造',
            illustrate: '剑琅联盟美业人才打造计划，秉持着“为中华民族栽培美业人才，走向国际舞台”的初衷。',
            content: '邹世欢以及Hyson梅晓峰。其中，邹世欢于2023年去往日本东京imaii学院进修学习；而Hyson则获得剑琅联盟携手沙宣前校长MeePoh罗美宝女士的精心栽培。'
          },{
            index: 0,
            year: '2023年',
            title: '剑琅联盟 美业人才打造',
            illustrate: '剑琅联盟美业人才打造计划，秉持着“为中华民族栽培美业人才，走向国际舞台”的初衷。',
            content: '邹世欢以及Hyson梅晓峰。其中，邹世欢于2023年去往日本东京imaii学院进修学习；而Hyson则获得剑琅联盟携手沙宣前校长MeePoh罗美宝女士的精心栽培。'
          }],
          illustrateStr: '美业获得了诸多成就后，Danny潘家辉先生还是毅然放弃了早年获得加拿大国籍的机会，回到祖国大陆，希望通过他孜孜不倦的社会奉献精神去推动未来中国美业的积极发展，为中华民族的伟大复兴贡献力量！2018年，Danny创立剑琅联盟，带领团队研发剑琅管家APP，志在帮助中小型美发门店解决各类痛点。',
          illustrateStr1: '剑琅联盟，让美业人更专业、更赚钱！',
          timeLineList:['2018','2019','2020']
        }
    }

    componentDidMount() {}

    handleTimelineDote(index){
      this.carouselEL.current.goTo(index);
      this.setState({
        currentTimeLineIndex:index
      })
    }

    render() {
        return (
            <div className="about-me">
                <Header index={4} history={this.props.history}></Header>
                <FloatToast></FloatToast>
                <div className="about-me-introduce flex">
                  <img className="img1" src={about1} alt="img"></img>
                  <div className="right">
                    <img className="img2 mt140" src={about7} alt="img"/>
                    {this.state.illustrates.map((item, index) => {
                      return (
                        <div className="item flex mb40" key={index}>
                          <img className="item-img mr20" src={item.image} alt="img"></img>
                          <p className="item-title">{item.title}</p>
                        </div>
                        );
                    })}
                  </div>
                </div>
                <div className="about_me_banner_warp flex_column_center mt25">
                  <div className="about_me_banner_title f50 color_black mt50">剑琅联盟 美业人才打造</div>
                  <div className="about_me_banner_desc f30 mt20">剑琅联盟美业人才打造计划，秉持着“为中华民族栽培美业人才，走向国际舞台”的初衷。</div>
                  <div className="about_me_banner_card">
                    {
                      this.state.talentsImgList.map((item, index) => {
                        return (
                            <img  className="about_me_banner_card_item_img" src={item} key={index} alt="img" />
                        )
                      })
                    }
                  </div>
                </div>
                <div className="h15"></div>
                <div className="about-me-illustrate" style={this.state.backgroundObj}>
                    <div className="one">{this.state.illustrateStr}</div>
                    <p className="one two">{this.state.illustrateStr1}</p>
                </div>
                <div className="h30"></div>
                <div className="about-me-flow"></div>
                <Download history={this.props.history}></Download>
                <div className="h20"></div>
                <Footer index={4} history={this.props.history}></Footer>
                <ScrollTop></ScrollTop>
            </div>
        )
    }
}