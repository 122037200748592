import React, { Component } from "react";
import Header from "../../components/header/header";
import FloatToast from "../../components/float-toast/float-toast";
import Download from "../../components/download/download";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop/scrollTop";
import systemStateBg from "../../static/images/product/system_state_bg.png";
import systemStateIcon1 from "../../static/images/product/system_state_icon1.png";
import systemStateIcon2 from "../../static/images/product/system_state_icon2.png";
import systemStateIcon3 from "../../static/images/product/system_state_icon3.png";
import systemStateIcon4 from "../../static/images/product/system_state_icon4.png";
import systemStateIcon5 from "../../static/images/product/system_state_icon5.png";
import systemStateIcon6 from "../../static/images/product/system_state_icon6.png";
import systemStateIcon7 from "../../static/images/product/system_state_icon7.png";
import ISOIcon from "../../static/images/product/ISO_logo.png";
import AAAIcon from "../../static/images/product/AAA_logo.png";
import qpIcon from "../../static/images/product/qp_logo.png";
import { Image,Popover  } from 'antd';
import Description from "./description/description";
import Safe from "./safe/safe";
import System from "../system/system";
import ThirdPartner from "./thirdpartnar/thirdpartnar";
import { _ } from "../../common/request";

import "./product.css";
export default class Product extends Component {
    constructor(props){
        super(props);
        this.state = {
          currentTabIndex: 0,
          systemStateList:[
            {title:'店铺',desc:'店铺主页、微页面的浏览',icon:systemStateIcon1},
            {title:'商品',desc:'商品详情的浏览',icon:systemStateIcon2},
            {title:'交易',desc:'购物车、下单、订单核销',icon:systemStateIcon3},
            {title:'支付',desc:'买家下单付款',icon:systemStateIcon4},
            {title:'账号',desc:'买家的注册、登录及店铺管理',icon:systemStateIcon5},
            {title:'营销',desc:'优惠券/码、限时折扣、满减送、拼团等营销玩法',icon:systemStateIcon6},
            {title:'消息',desc:'智能客服咨询服务等消息业务',icon:systemStateIcon7},
          ],
          systemStateCollectList:[
          ],
          internationalCertificationList:[
            {title:'Iso27001',desc:'信息安全管理体系认证',icon:ISOIcon},
            {title:'Iso9001',desc:'信息安全管理体系认证',icon:ISOIcon},
            {title:'',desc:'省级高新技术企业研究开发中心',icon:qpIcon},
            {title:'AAA级',desc:'AAA级信用企业',icon:AAAIcon},
          ]
        }
    }

    componentDidMount() {
      if(this.props.location.state){
        this.handleChangeTab(this.props.location.state.index)
      }
      if(localStorage.getItem('currentTabsInIndex')){
        this.handleChangeTab(localStorage.getItem('currentTabsInIndex')*1)
      }
    }
    componentWillUnmount = () => {
      localStorage.removeItem('currentTabsInIndex')
    }
    // 获取服务器状态
    getServerState(){
      let params = {
        env_type:"jy-prd"
      }
      _.fetchServerStateList(params).then(res=>{
        if(res.success === '0'){
          let arr = res.val
          this.setState({
            systemStateCollectList: arr,
          })
        }
      })
    }

    handleChangeTab(data){
      if(data === 4){
        this.getServerState();
      }
      this.setState({
        currentTabIndex: data
      })
    }
    render() {
        return (
          <div className="product">
            <Header index={2} history={this.props.history} handleChangeTab={this.handleChangeTab.bind(this)}></Header>
            <FloatToast></FloatToast>
            {
              this.state.currentTabIndex === 0 ? <System></System>
               :this.state.currentTabIndex === 1 ? 
               <Description></Description>
              : this.state.currentTabIndex === 2 ? 
              <Safe currentTabIndex={this.state.currentTabIndex}  handleChangeTab={this.handleChangeTab.bind(this)}></Safe> 
              : this.state.currentTabIndex === 3 ? <ThirdPartner></ThirdPartner>:
              <div className="product-content">
                <div className="system_state flex_column_center">
                  <div className="system_state_title fb f50 color_black mt110">系统状态</div>
                  <div className="system_state_content mt74">
                    {
                      this.state.systemStateList.map((item,index)=>{
                        return (
                          <div className="system_state_item flex_column_center" key={index}>
                            <div className="system_state_bg"><Image preview={false} src={systemStateBg} /></div>
                            <div className="system_state_icon mt75"> <Image preview={false} src={item.icon}  /></div>
                            <div className="system_state_item_title fb f20 mt17">{item.title}</div>
                            <div className="system_state_item_desc fw400 f18 mt17 tc">{item.desc}</div>
                          </div>
                        )
                      })
                    }
                  </div>
  
                  <div className="state_collect mt30 flex_absolute_center">
                    <div className="state_collect_card flex_column_center">
                      <div className="state_collect_card_title fb f40 mt40">90天状态汇总</div>
                      <div className="state_collect_card_content mt20 flex_between">
                        <div className="state_collect_card_item">
                          {
                            this.state.systemStateCollectList.splice(0,30).map((item,index)=>{
                              return (<Popover content={'服务器'+item.describe} title={item.date} key={index}>
                                <div className={`state_dote mb20 mr10 ${item.describe==='正常'?'':'state_dote_abnormality'}`}></div>
                              </Popover>)
                            })
                          }
                        </div>
                        <div className="state_collect_card_item">
                          {
                            this.state.systemStateCollectList.splice(0,30).map((item,index)=>{
                              return (<Popover content={'服务器'+item.describe} title={item.date} key={index}>
                                <div className={`state_dote mb20 mr10 ${item.describe==='正常'?'':'state_dote_abnormality'}`}></div>
                              </Popover>)
                            })
                          }
                        </div>
                        <div className="state_collect_card_item">
                          {
                            this.state.systemStateCollectList.splice(0,30).map((item,index)=>{
                              return (<Popover content={'服务器'+item.describe} title={item.date} key={index}>
                                <div className={`state_dote mb20 mr10 ${item.describe==='正常'?'':'state_dote_abnormality'}`}></div>
                              </Popover>)
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                        
                <div className="international_certification color_black flex_column_center">
                  <div className="international_certification_title fb f50 mt80">获得多项国际认证，堪比银行级的安全保障</div>
                  <div className="international_certification_list flex_absolute_center mt60 mb160">
                      {
                        this.state.internationalCertificationList.map((item, index) => {
                          return (
                            <div className="international_certification_item flex_column_center" key={index}>
                              <div className="international_certification_item_icon"><Image preview={false} src={item.icon} alt="" /></div>
                               {item.title? <div className="international_certification_item_title fb f30 mt7">{item.title}</div>:null}
                              <div className="international_certification_item_desc f22 mt12 tc" style={{width: index===2?'220px':'auto'}}>{item.desc}</div>
                            </div>
                          )
                        })
                      }
                  </div>
                </div>  
              </div>
            }
            {this.state.currentTabIndex !== 1&&<Download history={this.props.history}></Download>}
            <div className="h20"></div>
            <Footer index={2} history={this.props.history} handleChangeTab={this.handleChangeTab.bind(this)}></Footer>
            <ScrollTop></ScrollTop>
          </div>
        )
    }
}