import React, { Component } from 'react';
import MobileHeader from '../../../components/mobileHeader/mobileHeader';
import MobileFooter from '../../../components/mobileFooter/mobileFooter';
import './home.css'
import { Swiper,Tabs  } from 'antd-mobile'
import { PlusOutlined } from '@ant-design/icons';
import homeBannerTabsItemIcon1 from '../../../static/home/home_banner_tabs_item_icon1.png'
import homeBannerTabsItemIcon2 from '../../../static/home/shopkeeping_system_content_item_icon32.png'
import homeBannerTabsItemIcon3 from '../../../static/home/home_banner_tabs_item_icon3.png'
import homeBannerTabsItemIcon4 from '../../../static/home/home_banner_tabs_item_icon4.png'
import homeBannerTabsItemImg1 from '../../../static/home/home_banner_tabs_item_img1.png'
import homeBannerTabsItemImg2 from '../../../static/home/home_banner_tabs_item_img2.png'
import homeBannerTabsItemImg3 from '../../../static/home/home_banner_tabs_item_img3.png'
import homeBannerTabsItemImg4 from '../../../static/home/home_banner_tabs_item_img4.png'
import offerPlanIcon1 from '../../../static/home/offer_plan_1.png'
import offerPlanIcon2 from '../../../static/home/offer_plan_2.png'
import offerPlanIcon3 from '../../../static/home/offer_plan_3.png'
import offerPlanIcon4 from '../../../static/home/offer_plan_4.png'
import offerPlanIcon5 from '../../../static/home/offer_plan_5.png'
import kernelBusinessImg1 from '../../../static/home/kernel_business_img1.png'
import kernelBusinessImg2 from '../../../static/home/kernel_business_img2.png'
import kernelBusinessImg3 from '../../../static/home/kernel_business_img3.png'
import kernelBusinessImg4 from '../../../static/home/kernel_business_img4.png'
import basicModuleIcon1 from '../../../static/home/basic_module_icon1.png'
import basicModuleIcon2 from '../../../static/home/basic_module_icon2.png'
import basicModuleIcon3 from '../../../static/home/basic_module_icon3.png'
import basicModuleIcon4 from '../../../static/home/basic_module_icon4.png'
import basicModuleIcon5 from '../../../static/home/basic_module_icon5.png'
import basicModuleIcon6 from '../../../static/home/basic_module_icon6.png'
import basicModuleIcon7 from '../../../static/home/basic_module_icon7.png'
import basicModuleIcon8 from '../../../static/home/basic_module_icon8.png'
import basicModuleIcon9 from '../../../static/home/basic_module_icon9.png'
import basicModuleIcon10 from '../../../static/home/basic_module_icon10.png'
import basicModuleIcon11 from '../../../static/home/basic_module_icon11.png'
import basicModuleIcon12 from '../../../static/home/basic_module_icon12.png'
import basicModuleIcon13 from '../../../static/home/basic_module_icon13.png'
import basicModuleIcon14 from '../../../static/home/basic_module_icon14.png'
import basicModuleIcon15 from '../../../static/home/basic_module_icon15.png'
import basicModuleIcon16 from '../../../static/home/basic_module_icon16.png'
import basicModuleIcon17 from '../../../static/home/basic_module_icon17.png'
import basicModuleIcon18 from '../../../static/home/basic_module_icon18.png'
import kernelAdvantageItemContentImg1 from '../../../static/home/kernel_advantage_item_content_img1.png'
import kernelAdvantageItemContentImg2 from '../../../static/home/kernel_advantage_item_content_img2.png'
import kernelAdvantageItemContentImg3 from '../../../static/home/kernel_advantage_item_content_img3.png'
import solutionItemBg1 from '../../../static/home/solution_item_bg1.png'
import solutionItemBg2 from '../../../static/home/solution_item_bg2.png'
import solutionItemBg3 from '../../../static/home/solution_item_bg3.png'
import solutionItemBg4 from '../../../static/home/solution_item_bg4.png'
import solutionItemBg5 from '../../../static/home/solution_item_bg5.png'
import solutionItemBg6 from '../../../static/home/solution_item_bg6.png'
import solutionItemIcon1 from '../../../static/home/solution_item_icon1.png'
import solutionItemIcon2 from '../../../static/home/solution_item_icon2.png'
import solutionItemIcon3 from '../../../static/home/solution_item_icon3.png'
import solutionItemIcon4 from '../../../static/home/solution_item_icon4.png'
import solutionItemIcon5 from '../../../static/home/solution_item_icon5.png'
import solutionItemIcon6 from '../../../static/home/solution_item_icon6.png'
import ourAdvantageImg from '../../../static/home/our_advantage_img.png'
import productThumbnailItemImg1 from '../../../static/home/product_thumbnail_item_img1.png'
import productThumbnailItemImg2 from '../../../static/home/product_thumbnail_item_img2.png'
import productThumbnailItemImg3 from '../../../static/home/product_thumbnail_item_img3.png'
import productThumbnailItemImg4 from '../../../static/home/product_thumbnail_item_img4.png'
import productThumbnailItemImg5 from '../../../static/home/product_thumbnail_item_img5.png'
import shopkeepingSystemTabIcon1 from '../../../static/home/shopkeeping_system_tab_icon1.png'
import shopkeepingSystemTabIcon2 from '../../../static/home/shopkeeping_system_tab_icon2.png'
import shopkeepingSystemTabIcon3 from '../../../static/home/shopkeeping_system_tab_icon3.png'
import shopkeepingSystemTabIcon4 from '../../../static/home/shopkeeping_system_tab_icon4.png'
import shopkeepingSystemContentItemIcon11 from '../../../static/home/shopkeeping_system_content_item_icon11.png'
import shopkeepingSystemContentItemIcon12 from '../../../static/home/shopkeeping_system_content_item_icon12.png'
import shopkeepingSystemContentItemIcon13 from '../../../static/home/shopkeeping_system_content_item_icon13.png'
import shopkeepingSystemContentItemIcon14 from '../../../static/home/shopkeeping_system_content_item_icon14.png'
import shopkeepingSystemContentItemIcon15 from '../../../static/home/shopkeeping_system_content_item_icon15.png'
import shopkeepingSystemContentItemIcon21 from '../../../static/home/shopkeeping_system_content_item_icon21.png'
import shopkeepingSystemContentItemIcon22 from '../../../static/home/shopkeeping_system_content_item_icon22.png'
import shopkeepingSystemContentItemIcon23 from '../../../static/home/shopkeeping_system_content_item_icon23.png'
import shopkeepingSystemContentItemIcon24 from '../../../static/home/shopkeeping_system_content_item_icon24.png'
import shopkeepingSystemContentItemIcon25 from '../../../static/home/shopkeeping_system_content_item_icon25.png'
import shopkeepingSystemContentItemIcon31 from '../../../static/home/shopkeeping_system_content_item_icon31.png'
import shopkeepingSystemContentItemIcon32 from '../../../static/home/shopkeeping_system_content_item_icon32.png'
import shopkeepingSystemContentItemIcon33 from '../../../static/home/shopkeeping_system_content_item_icon33.png'
import shopkeepingSystemContentItemIcon34 from '../../../static/home/shopkeeping_system_content_item_icon34.png'
import shopkeepingSystemContentItemIcon41 from '../../../static/home/shopkeeping_system_content_item_icon41.png'
import shopkeepingSystemContentItemIcon42 from '../../../static/home/shopkeeping_system_content_item_icon42.png'
import shopkeepingSystemContentItemImg11 from '../../../static/home/shopkeeping_system_content_item_img11.png'
import shopkeepingSystemContentItemImg12 from '../../../static/home/shopkeeping_system_content_item_img12.png'
import shopkeepingSystemContentItemImg13 from '../../../static/home/shopkeeping_system_content_item_img13.png'
import shopkeepingSystemContentItemImg14 from '../../../static/home/shopkeeping_system_content_item_img14.png'
import shopkeepingSystemContentItemImg15 from '../../../static/home/shopkeeping_system_content_item_img15.png'
import shopkeepingSystemContentItemImg21 from '../../../static/home/shopkeeping_system_content_item_img21.png'
import shopkeepingSystemContentItemImg22 from '../../../static/home/shopkeeping_system_content_item_img22.png'
import shopkeepingSystemContentItemImg23 from '../../../static/home/shopkeeping_system_content_item_img23.png'
import shopkeepingSystemContentItemImg24 from '../../../static/home/shopkeeping_system_content_item_img24.png'
import shopkeepingSystemContentItemImg25 from '../../../static/home/shopkeeping_system_content_item_img25.png'
import shopkeepingSystemContentItemImg31 from '../../../static/home/shopkeeping_system_content_item_img31.png'
import shopkeepingSystemContentItemImg32 from '../../../static/home/shopkeeping_system_content_item_img32.png'
import shopkeepingSystemContentItemImg33 from '../../../static/home/shopkeeping_system_content_item_img33.png'
import shopkeepingSystemContentItemImg34 from '../../../static/home/shopkeeping_system_content_item_img34.png'
import shopkeepingSystemContentItemImg41 from '../../../static/home/shopkeeping_system_content_item_img41.png'
import shopkeepingSystemContentItemImg42 from '../../../static/home/shopkeeping_system_content_item_img42.png'
import customerImg from "../../../static/customer/customer_success_img.png"
import customerImg2 from "../../../static/customer/customer_success_img2.png"
import customerImg3 from "../../../static/customer/customer_success_img3.png"

export default class HomeMobile extends Component {
  constructor(props) {
    super(props);
    this.SwiperRef= React.createRef();
    this.ManageSwiperRef= React.createRef();
    this.pageRef = React.createRef();
    this.state = {
      bannerTabsList:[
        {label:'预约',icon:homeBannerTabsItemIcon1,img: homeBannerTabsItemImg1,},
        {label:'店务管理（一体机）',icon:homeBannerTabsItemIcon2,img: homeBannerTabsItemImg2,},
        {label:'营销/报表',icon:homeBannerTabsItemIcon3,img: homeBannerTabsItemImg3,},
        {label:'收银台开单',icon:homeBannerTabsItemIcon4,img: homeBannerTabsItemImg4,},
      ],
      currentBannerIndex:0,
      offerPlanList:[
        {title:'剑琅美业SaaS产品',desc:"便捷化操作流程,简化员工软件操作提升效率专注服务",icon: offerPlanIcon1},
        {title:'美业代运营服务',desc:"智能化店务管理,人财事物全面管理智能分析支持决策",icon: offerPlanIcon2},
        {title:'经营管理解决方案',desc:"一体化经营管理,连锁门店统一管理适配多种经营模式",icon: offerPlanIcon3},
        {title:'美业全域引流获客',desc:"精细化客户运营,轻松建档洞悉客户千人千面个性营销",icon: offerPlanIcon4},
        {title:'美业生态链',desc:"多样化营销拓客,线上线下全面打通海量营销管理工具",icon: offerPlanIcon5}
      ],
      teamPerformance:[
        {title:'5万+',desc:'为5万美业手艺人提供专业服务'},
        {title:'1万+',desc:'全国1万多家美业门店的选择'},
        {title:'3百万+',desc:'服务300万会员消费者'},
        {title:'50亿',desc:'年交易额50亿'},
      ],
      coreBusinessList:[
        {title:'开单记账',desc:"开单方便快捷，流程简洁开单方便",remark:'在线开单让我们的效率提高了85%，开启了我们无纸化办公的第一步',icon:kernelBusinessImg1},
        {title:'手机开单',desc:"开单方便快捷，流程简洁",remark:'在线开单让我们的效率提高了85%，开启了我们无纸化办公的第一步',icon:kernelBusinessImg2},
        {title:'营销工具',desc:"帮助商家轻松拓客、留存",remark:'优惠券、客户分群等功能帮助门店拓客、裂变、留存，随时查看营销数据',icon:kernelBusinessImg3},
        {title:'数据统计',desc:"将门店数据进行实时统计、分析、对比",remark:'每天节省统计数据的时间，实时进行数据的统计。比人工快速、精准！',icon:kernelBusinessImg4},
      ],
      basicModuleList:[
        {title:'经营分析',icon: basicModuleIcon1},
        {title:'客户管理',icon: basicModuleIcon2},
        {title:'自助收银',icon: basicModuleIcon3},
        {title:'支出管理',icon: basicModuleIcon4},
        {title:'营销短信',icon: basicModuleIcon5},
        {title:'库存管理',icon: basicModuleIcon6},
        {title:'优惠券',icon: basicModuleIcon7},
        {title:'卡管理',icon: basicModuleIcon8},
        {title:'店铺口碑',icon: basicModuleIcon9},
        {title:'作品管理',icon: basicModuleIcon10},
        {title:'物料商城',icon: basicModuleIcon11},
        {title:'价目表',icon: basicModuleIcon12},
        {title:'员工管理',icon: basicModuleIcon13},
        {title:'商城',icon: basicModuleIcon14},
        {title:'消费短信',icon: basicModuleIcon15},
        {title:'门店管理',icon: basicModuleIcon16},
        {title:'员工薪资',icon: basicModuleIcon17},
        {title:'提成设置',icon: basicModuleIcon18},
      ],
      carouselItemList: [
        {imgUrl:customerImg,name:'C·H Hair Salon',shopManagerName:'店长：Cinthia',message:'被剑琅管家slogan吸引“简单·易用·有效果”，使用后确实体会到了系统操作的便利性和专业性。帮我在各个方面都提升了很多，会员与客情管理都让我非常惊喜，使用后再也；不怕客户流失了，还能帮我门店全面分析运营数据报表，真的节省了很多时间和精力。'},
        {imgUrl:customerImg2,name:'恋恋美甲美睫沙龙',shopManagerName:'经理：小蕊',message:'我们店铺借助剑琅管家SaaS系统中的营销工具抓住了附近绝大部分的客源，包括老带新、线上商城等渠道，帮我们完全打通线上与线下，营业额在短短一个月里就达到了翻倍。而且我能看出剑琅管家的售后客服团队也非常专业，每次系统更新迭代，就会联系我们讲解如何使用新功能，真的非常贴心。'},
        {imgUrl:customerImg3,name:'CARE U Beauty皮肤管理中心',shopManagerName:'总负责人：Sean',message:'剑琅管家丰富的店务管理功能+灵活的卡项玩法+实用多样的营销工具+专业的售后服务，给我们美容院提供了有力支持，让我们能更好地落实员工的奖罚机制，运维好客户，懂得怎么做好营销活动。客户满意才是我们的宗旨，希望未来和剑琅管家一起发展得越来越好！'},
      ],
      kernelAdvantageList: [
        {title:'简单',desc:['极简流程，页面交互既美观又简约'],icon: kernelAdvantageItemContentImg1},
        {title:'易用',desc:['无需人工培训，即可上手操作，功能齐全不繁琐'],icon: kernelAdvantageItemContentImg2},
        {title:'有效果',desc:['深耕美业30余年，有专业研发团队','帮助美业商家轻松经营、高效管理','随时随地了解门店流水，','数据同步，安全可靠，永不丢失',],icon: kernelAdvantageItemContentImg3},
      ],
      solutionList: [
        {title:'美发',icon: solutionItemIcon1,backgroundImg: solutionItemBg1},
        {title:'美甲美瞳',icon: solutionItemIcon2,backgroundImg: solutionItemBg2},
        {title:'养发馆',icon: solutionItemIcon3,backgroundImg: solutionItemBg3},
        {title:'皮肤管理',icon: solutionItemIcon4,backgroundImg: solutionItemBg4},
        {title:'足浴',icon: solutionItemIcon5,backgroundImg: solutionItemBg5},
        {title:'美容SPA',icon: solutionItemIcon6,backgroundImg: solutionItemBg6},
      ],
      ourAdvantageList: [
        {title:'强大的技术实力与售后保障',desc:"强大的技术实力与售后保障专业的客户成功团队专业的客户成功团队",icon:'../static/images/close.png'},
        {title:'专业的客户成功团队',desc:"专业的客户成功团队专业的客户成功团队",icon:'../static/images/close.png'},
        {title:'平台代运营和其他增值服务',desc:"专业的客户成功团队，专业的客户成功团队专业的客户成功团队专业的客户成功团队专业的客户成功团队",icon:'../static/images/close.png'},
      ],
      productThumbnailList: [
        {title:'IPAD平板',icon: productThumbnailItemImg1,desc:'首页 文案文案文案文案',url:''},
        {title:'一体机',icon: productThumbnailItemImg2,desc:'资金账户 文案文案文案文案',url:''},
        {title:'小程序',icon: productThumbnailItemImg3,desc:'经营数据合集 文案文案文案文案',url:''},
        {title:'软件功能',icon: productThumbnailItemImg4,desc:'经营数据一览 文案文案文案文案',url:''},
        {title:'活动',icon: productThumbnailItemImg5,desc:'店铺算收益 文案文案文案文案',url:''},
      ],
      currentManageBannerIndex:0,
      shopkeepingSystemList: [
        {
          title:'APP',
          icon:shopkeepingSystemTabIcon1,
          itemArray:[
            {title:'开单记账',infoImg:shopkeepingSystemContentItemImg11,icon:shopkeepingSystemContentItemIcon11},
            {title:'客户预约',infoImg:shopkeepingSystemContentItemImg12,icon:shopkeepingSystemContentItemIcon12},
            {title:'会员管理',infoImg:shopkeepingSystemContentItemImg13,icon:shopkeepingSystemContentItemIcon13},
            {title:'员工管理',infoImg:shopkeepingSystemContentItemImg14,icon:shopkeepingSystemContentItemIcon14},
            {title:'短信中心',infoImg:shopkeepingSystemContentItemImg15,icon:shopkeepingSystemContentItemIcon15},
          ]
        },
        {title:'一体机',
        icon:shopkeepingSystemTabIcon2,
        itemArray:[
          {title:'收银台',infoImg:shopkeepingSystemContentItemImg21,icon:shopkeepingSystemContentItemIcon21},
          {title:'客户预约',infoImg:shopkeepingSystemContentItemImg22,icon:shopkeepingSystemContentItemIcon22},
          {title:'流水订单',infoImg:shopkeepingSystemContentItemImg23,icon:shopkeepingSystemContentItemIcon23},
          {title:'会员管理',infoImg:shopkeepingSystemContentItemImg24,icon:shopkeepingSystemContentItemIcon24},
          {title:'短信中心',infoImg:shopkeepingSystemContentItemImg25,icon:shopkeepingSystemContentItemIcon25},
        ]},
        {title:'小程序',
        icon:shopkeepingSystemTabIcon3,
        itemArray:[
          {title:'商城首页',infoImg:shopkeepingSystemContentItemImg31,icon: shopkeepingSystemContentItemIcon31},
          {title:'自助收银',infoImg:shopkeepingSystemContentItemImg32,icon: shopkeepingSystemContentItemIcon32},
          {title:'预约中心',infoImg:shopkeepingSystemContentItemImg33,icon: shopkeepingSystemContentItemIcon33},
          {title:'个人中心',infoImg:shopkeepingSystemContentItemImg34,icon: shopkeepingSystemContentItemIcon34},
        ]},
        {title:'公众号',
        icon:shopkeepingSystemTabIcon4,
        itemArray:[
          {title:'消息推送',infoImg:shopkeepingSystemContentItemImg41,icon:shopkeepingSystemContentItemIcon41},
          {title:'行业资讯',infoImg:shopkeepingSystemContentItemImg42,icon:shopkeepingSystemContentItemIcon42},
        ]},
      ],
      currentShopKeepingIndex: 0,
      currentShopKeepingImg: shopkeepingSystemContentItemImg11,
    };
  }
  componentDidMount() {
  }
  componentWillUnmount() {
  }
  
  // 点击bannertab
  handleBannerTabs(index) {
    this.setState({
      currentBannerIndex: index,
    });
    this.SwiperRef.current?.swipeTo(index);
  }

  handleManageBannerTabs(index){
    let array = this.state.shopkeepingSystemList
    this.setState({
      currentManageBannerIndex: index,
      currentShopKeepingIndex: 0,
      currentShopKeepingImg: array[index].itemArray[0].infoImg
    });
    this.ManageSwiperRef.current?.swipeTo(index);
  }

  handleManageSlideTabs(index){
    let array = this.state.shopkeepingSystemList
    this.setState({
      currentShopKeepingIndex: index,
      currentShopKeepingImg: array[this.state.currentManageBannerIndex].itemArray[index].infoImg
    });
  }

  jumpToProduct(){
    this.props.history.push({ pathname: "/product",state:{index:1}});
    window.scrollTo({
      top: 0, 
      behavior: 'auto',
    });
  }
  render() {
    let { 
            bannerTabsList,
            currentBannerIndex,
            offerPlanList,
            teamPerformance,
            coreBusinessList,
            basicModuleList,
            carouselItemList,
            kernelAdvantageList,
            solutionList,ourAdvantageList,
            productThumbnailList,
            currentManageBannerIndex,
            shopkeepingSystemList,
            currentShopKeepingImg,
            currentShopKeepingIndex,
          } = this.state;
    return (
      <div ref={this.pageRef} className="page_mobile">
          <MobileHeader index={0} history={this.props.history}></MobileHeader>
          <div className='mobile_home_banner'>
            <div className='mobile_home_banner_title'>10000+美业门店</div>
            <div className='mobile_home_banner_desc'>都在使用的会员管理系统</div>
            <Tabs className='mobile_home_tabs' activeKey={currentBannerIndex} onChange={this.handleBannerTabs.bind(this)}>
              {
                bannerTabsList.map((item,index)=>{
                  return <Tabs.Tab title={item.label} key={index}></Tabs.Tab>
                })
              }
            </Tabs>
            <Swiper ref={this.SwiperRef} className='mobile_home_swiper' indicator={false} loop onIndexChange={i => {this.setState({ currentBannerIndex: i })}}>
              {
                bannerTabsList.map((item,index)=>{
                  return <Swiper.Item className={`mobile_home_swiper_card flex_absolute_center ${index===currentBannerIndex?'mobile_home_banner_tabs_item_active':''}`} key={index}>
                    <img style={{width:'100%',height:'100%'}} alt='' src={item.img} />
                  </Swiper.Item>
                  })
              }
            </Swiper>
          </div>

          {/* 为您提供解决方案，专业解决美业行业典型问题 */}
          <div className='mobile_offer_plan_wrap'>
            <div className='mobile_offer_plan_title'>为您提供解决方案</div>
            <div className='mobile_offer_plan_title' style={{textAlign:'end'}}>专业解决美业行业典型问题</div>
            <div className='mobile_offer_plan_card_bag'>
              {
                offerPlanList.map((item,index)=>{
                  return <div className='flex_column_center' key={index}>
                  <div className='mobile_offer_plan_card flex_absolute_center'>
                    <img className='mobile_offer_plan_card_img' alt='' src={item.icon} />
                    <div className='mobile_offer_plan_card_right'>
                      <div className='mobile_offer_plan_card_title'>{item.title}</div>
                      <div className='mobile_offer_plan_card_desc'>{item.desc}</div>
                    </div>
                    </div>
                    
                     {index<offerPlanList.length-1&&<PlusOutlined className='mobile_offer_plan_card_plus' />}
                  </div>
                })
              }
            </div>
          </div>

          {/* 以我专业 让您优越 */}
          <div className='mobile_team_performance_wrap flex_column_center'>
            <div className='mobile_team_performance_title'>以我专业 让您优越</div>
            <div className='mobile_team_performance_desc'>30多年IT+美业经验，专业核心研发团队</div>
            <div className='mobile_team_performance_item flex_around'>
              {
                teamPerformance.map((item,index)=>{
                  return (<div className='mobile_team_performance_item_card' key={index}>
                    <div className='mobile_team_performance_item_card_title'>{item.title}</div>
                    <div className='mobile_team_performance_item_card_desc'>{item.desc}</div>
                  </div>)
                })
              }
            </div>
          </div>

          {/* 剑琅核心业务 */}
          <div className='mobile_core_business'>
            <div className='mobile_core_business_title'>剑琅核心业务</div>
            <div className='mobile_core_business_wrap'>
              {
                coreBusinessList.map((item,index)=>{
                  return (<div className='mobile_core_business_card' key={index}>
                    <div className='mobile_core_business_card_info'>
                      <div className='mobile_core_business_card_title'>{item.title}</div>
                      <div className='mobile_core_business_card_desc'>{item.desc}</div>
                      <div className='mobile_core_business_card_remark'>
                        <div className="mobile_quotation_mark_top">“</div>
                        {item.remark}
                        <div className="mobile_quotation_mark_bottom">”</div>
                      </div>
                    </div>
                    <img src={item.icon} className="mobile_core_business_card_img" alt='' />
                  </div>)
                })
              }
            </div>
          </div>
          {/* 常见的基础模块？ */}
          <div className='mobile_common_basic_module'>
            <div className='mobile_common_basic_module_title'>常见基础模块</div>
            <div className='mobile_common_basic_module_desc'>丰富好玩的营销工具，多种卡项权益，新潮的互动游戏，全面赋能营销获客</div>
            <div className='mobile_common_basic_module_wrap'>
              {
                basicModuleList.map((item,index)=>{
                  return (
                    <div className='mobile_common_basic_module_card' key={index}>
                      <img src={item.icon} alt='' className='mobile_common_basic_module_card_img'/>
                      <div  className='mobile_common_basic_module_card_title'>{item.title}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>

          {/* 我们的客户都这样说... */}
          <div className='mobile_our_member_say flex_column_center'>
            <div className='mobile_our_member_say_title'>我们的客户都这样说...</div>
            <Swiper className='mobile_our_member_say_swiper' indicator={false} autoplay loop>
              {
                carouselItemList.map((item,index)=>{
                  return <Swiper.Item className="mobile_our_member_say_swiper_card" key={index}>
                    <img className="mobile_our_member_say_swiper_card_img" alt='' src={item.imgUrl} />
                    <div className="mobile_our_member_say_swiper_card_info">
                      <div className="mobile_our_member_say_swiper_card_info_title flex"><div className='mobile_our_member_say_dote'></div>{item.shopManagerName}说：</div>
                      <div className="mobile_our_member_say_swiper_card_info_desc  flex"><div className='mobile_our_member_say_dote'></div><div>{item.message}</div></div>
                    </div>
                  </Swiper.Item>
                  })
              }
            </Swiper>
          </div>

          {/* 头部用户的共同选择 */}
          <div className='mobile_header_member_select'>
            <div className='mobile_header_member_select_title'>头部用户的共同选择</div>
            <div className='mobile_header_member_select_desc'>
              <div className='mobile_header_member_select_desc_item'>
                <div className='mobile_header_member_select_desc_item_num'>1万+</div>门店
              </div>
              <div className='mobile_header_member_select_desc_item'>
                <div className='mobile_header_member_select_desc_item_num'>10+</div>相关行业
              </div>
              <div className='mobile_header_member_select_desc_item'>
                <div className='mobile_header_member_select_desc_item_num'>108+</div>覆盖城市
              </div>
            </div>
            <div className='mobile_header_member_select_card'></div>
          </div>
          <div className='mobile_free_a_week'>
            <div className='mobile_free_a_week_left'>免费试用<div style={{fontSize:'0.5rem'}}>7</div>  天 + 一站式解决美业门店经营管理需求</div>
            <div className='mobile_free_a_week_btn tc' onClick={()=>this.jumpToProduct(this)}>立即了解</div>
          </div>

          {/* 三大核心优势 赋能美业商家 */}
          <div className='mobile_core_advantage'>
            <div className='mobile_core_advantage_title'>三大核心优势 赋能美业商家</div>
            <div  className='mobile_core_advantage_desc'>我们重视每一个细节，我们相信追求极致的用户体验才是对用户的负责。我们致力于成为您真正的移动管理专家</div>
            <div className='mobile_core_advantage_list flex_column_center'>
              {
                kernelAdvantageList.map((item,index)=>{
                  return (
                    <div className='mobile_core_advantage_list_item' key={index}>
                      {index%2!==0&&<img className='mobile_core_advantage_list_item_icon' alt='' src={item.icon} />}
                      <div className='mobile_core_advantage_list_item_info'>
                        <div className='mobile_core_advantage_list_item_title'><div className='mobile_core_advantage_list_item_title_icon'></div>{item.title}</div>
                        {
                          item.desc.map((item1,index1)=>{
                            return <div className='mobile_core_advantage_list_item_desc' key={index1}><div className='mobile_core_advantage_list_item_desc_icon'></div>{item1}</div>
                          })
                        }
                      </div>
                      {index%2===0&&<img className='mobile_core_advantage_list_item_icon' alt='' src={item.icon} />}
                    </div>
                  )
                })
              }
            </div>
          </div>

          {/* 各行各业都有解决方案 */}
          <div className='mobile_solution_wrap'>
            <div className='mobile_solution_title'>各行各业都有解决方案</div>
            <div className='mobile_solution_desc'>专注美业信息化服务，多业态门店全适用</div>
            <div className='mobile_solution_list'>
              {
                solutionList.map((item,index)=>{
                  return(
                  <div className='mobile_solution_item' key={index} style={{background:`url(${item.backgroundImg})`,backgroundSize:'100% 100%'}}>
                      <div className='mobile_solution_item_info'>
                        <div className='mobile_solution_item_title'>{item.title}</div>
                        <img src={item.icon} alt='' className='mobile_solution_item_desc'/>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>

          {/* 剑琅管家店务管理系统 */}
          <div className='mobile_manage_system flex_column_center'>
            <div className='mobile_manage_system_title'>剑琅管家店务管理系统</div>
            <Tabs className='mobile_manage_system_tabs' activeKey={currentManageBannerIndex} onChange={this.handleManageBannerTabs.bind(this)}>
              {
                shopkeepingSystemList.map((item,index)=>{
                  return <Tabs.Tab title={
                    <div className='flex_column_center'>
                      <img src={item.icon} alt='' className='mobile_manage_system_tabs_icon'/>
                      <div className='mobile_manage_system_tabs_label'>{item.title}</div>
                    </div>
                  } key={index}></Tabs.Tab>
                })
              }
            </Tabs>
            <Swiper ref={this.ManageSwiperRef} className='mobile_manage_system_swiper' indicator={false} loop onIndexChange={i => {this.setState({ currentManageBannerIndex: i })}}>
              {
                shopkeepingSystemList.map((item,index)=>{
                  return <Swiper.Item className={`mobile_manage_system_swiper_card ${index===currentManageBannerIndex?'mobile_home_banner_tabs_item_active':''}`} key={index}>
                    <div className='mobile_manage_system_swiper_card_side_bar flex_column_center'>
                      {item.itemArray.map((item1,index1)=>{
                        return <div className='mobile_manage_system_swiper_card_side_bar_item' key={index1} style={{backgroundColor:currentShopKeepingIndex===index1?'rgba(37,103,245,0.2)':'#ffffff'}}
                        onClick={this.handleManageSlideTabs.bind(this,index1)}
                        >
                            <img className='mobile_manage_system_swiper_card_side_bar_item_icon' alt='' src={item1.icon} />
                            <div className='mobile_manage_system_swiper_card_side_bar_item_title'>{item1.title}</div>
                          </div>
                      })}
                    </div>
                    <img className='mobile_manage_system_swiper_card_side_img' alt='' src={currentShopKeepingImg} />
                  </Swiper.Item>
                  })
              }
            </Swiper>
          </div>

          {/* 我们的优势 */}
          <div className='mobile_our_advantage flex_column_center'>
            <div className='mobile_our_advantage_title'>我们的优势</div>
            <div className='mobile_our_advantage_desc'>为美业商家全方位运营护航</div>
            <div className='mobile_our_advantage_list'>
              <img src={ourAdvantageImg} alt='' className='mobile_our_advantage_img' />
              {
                ourAdvantageList.map((item,index)=>{
                  return(
                    <div className='mobile_our_advantage_item' key={index}>
                      <div className='mobile_our_advantage_item_title'><div className='mobile_our_advantage_dote'></div>{item.title}</div>
                      <div className='mobile_our_advantage_item_desc'>{item.desc}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          
          <div className='mobile_home_product_wrap'>
            <Swiper className='mobile_home_product_swiper' indicator={false} autoplay loop>
              {
                productThumbnailList.map((item,index)=>{
                  return <Swiper.Item className="mobile_home_product_item" key={index}>
                  <div className='mobile_home_product_item_title'>{item.title}</div>
                  <div className='mobile_home_product_item_desc'>{item.desc}</div>
                  <img className='mobile_home_product_item_img' src={item.icon} alt='' />
                  </Swiper.Item>
                  })
              }
            </Swiper>
          </div>
          <MobileFooter ></MobileFooter>
      </div>
    );
  };
}