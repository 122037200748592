import React, { Component } from "react";
import Header from "../../components/header/header";
import Download from "../../components/download/download";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop/scrollTop";
import FloatToast from "../../components/float-toast/float-toast";
import LeaveInfoToast from "../../components/leaveInfoToast/leaveInfoToast";
import AppDownloadImg from '../../static/footer/APP_download_er_code.png'
import softwareContentImg from "../../static/images/software/software_content_img.png"
import softwareContentIcon1 from "../../static/images/software/software_content_icon1.png"
// import softwareContentIcon2 from "../../static/images/software/software_content_icon2.png"
import softwareContentIcon3 from "../../static/images/software/software_content_icon3.png"
import softwareContentIcon4 from "../../static/images/software/software_content_icon4.png"
import softwareModuleIcon1 from "../../static/images/system_module_icon1.png"
import softwareModuleIcon2 from "../../static/images/system_module_icon2.png"
import softwareModuleIcon3 from "../../static/images/system_module_icon3.png"
import softwareModuleIcon4 from "../../static/images/system_module_icon4.png"
import softwareModuleIcon5 from "../../static/images/system_module_icon5.png"
import softwareModuleIcon6 from "../../static/images/system_module_icon6.png"
import softwareModuleIcon7 from "../../static/images/system_module_icon7.png"
import buyPathCardItemIcon1 from "../../static/images/software/buy_path_card_item_icon1.png"
import buyPathCardItemIcon2 from "../../static/images/software/buy_path_card_item_icon2.png"
import { Image  } from 'antd';

import "./software.css";
export default class Software extends Component {
    constructor(props){
        super(props);
        this.state = {
            audioUrl: '',
            title: '剑琅联盟全新定制版',
            tip: '我们兼容：Windows 、Mac 、iOS 、Android',
            showInfoToastVisible:false,
            contents: [{
              image: softwareContentIcon1,
              dialogue: '剑琅管家Web版',
              showCode: false,
              button: '去使用'
              },{
              image: softwareContentIcon3,
              dialogue: '剑琅管家iOS版',
              button: '下载',
              showCode: false,
              erCodeImg: AppDownloadImg,
              },{
              image: softwareContentIcon4,
              dialogue: '剑琅管家Android版',
              showCode: false,
              button: '下载',
              erCodeImg: AppDownloadImg,
            }],
            functions: [{
              image: softwareModuleIcon1,
              name: '异业合作',
            },{
              image: softwareModuleIcon2,
              name: '流水订单',
            },{
              image: softwareModuleIcon3,
              name: '库存管理',
            },{
              image: softwareModuleIcon4,
              name: '经营分析',
            },{
              image: softwareModuleIcon5,
              name: '会员管理',
            },{
              image: softwareModuleIcon6,
              name: '自助收银',
            },{
              image: softwareModuleIcon7,
              name: '收益提现',
            }],
            more: '更多购买途径',
            buyPathList:[
              {icon:buyPathCardItemIcon1,name:'剑琅联盟天猫旗舰店',btnText:'去看看',url:'https://jianlanglianmeng.tmall.com/shop/view_shop.htm?spm=a21n57.1.3.1.69fb523chUdk0I&appUid=RAzN8HWSF3zy9KLv7xfjM1hLWS4nTHnbuwAR57Bxaan612EzV9A'},
              {icon:buyPathCardItemIcon2,name:'咨询客服购买',btnText:'召唤客服',url:''}
            ]
        }
    }

    jumpToH5(index) {
      if(index>=1) {
        window.open('https://download.round-table-union.com/download/rtb.html')
        return
      }
      window.open('https://webrtb.round-table-union.com')
    }

    handlePhoneDw(index0){
      let arr = this.state.contents;
      arr = arr.map((e, index) => {
        return {
          ...e,
          showCode: index===index0 ? true : false
        }
      })
      this.setState({
        contents: arr
      })
    }
    handlePhoneLeave(){
      let arr = this.state.contents;
      arr = arr.map((item) => { 
        return{
          ...item,
          showCode :false
        }
      })
      this.setState({
        contents: arr
      })
    }
    jumpToMore(url){
      if(url){
        window.open(url)
      }else{
        this.handleShowInfoToast()
      }
    }
    handleShowInfoToast() {
      let showInfoToastVisible = !this.state.showInfoToastVisible
      this.setState({
        showInfoToastVisible
      })
    }
    render() {
        return (
          <div className="software">
            <Header index={5} history={this.props.history}></Header>
            { this.state.showInfoToastVisible&&<LeaveInfoToast handleShowInfoToast={this.handleShowInfoToast.bind(this)}></LeaveInfoToast>}
            <FloatToast></FloatToast>
            <div className="software-content">
              <div className="software_header">
                <div className="title">{this.state.title}</div>
                <div className="tip">{this.state.tip}</div>
                <div className="software-downloads flex">
                {this.state.contents.map((item, index) => {
                  return (
                      <div className="item" key={index} onMouseEnter={index>0 ? this.handlePhoneDw.bind(this,index): null} onMouseLeave={this.handlePhoneLeave.bind(this)}>
                        <img className="img mt60" style={{transform: item.showCode ? 'scale(1.5)':''}} src={!item.showCode ? item.image:item.erCodeImg} alt="img"></img>
                        <p className="name mt40 mb40">{item.dialogue}</p>
                        <p className="button" onClick={()=>this.jumpToH5(index)}>{item.button}</p>
                      </div>
                      );
                  })}
                </div>
                <div className="software-function flex ju_bw">
                {this.state.functions.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                        <img className="img" src={item.image} alt="img"></img>
                        <p className="name">{item.name}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="function_image"><Image preview={false} src={softwareContentImg} /></div>
              <div className="software-more">{this.state.more}</div>
              <div className="buy_path_wrap mt40 flex_absolute_center">
                <div className="buy_path_card flex_absolute_center">
                  <div className="buy_path_card_wrap flex_absolute_center">
                    {
                      this.state.buyPathList.map((item, index) => {
                        return <div className="buy_path_card_item flex_column_center" key={index}>
                          <div className="buy_path_card_item_icon mt64" ><Image src={item.icon} preview={false} ></Image></div>
                          <div className="f24 color_black fw400 mt50">{item.name}</div>
                          <div className="buy_path_card_item_btn f24 color_white mt30 tc" onClick={()=>this.jumpToMore(item.url)}>{item.btnText}</div>
                        </div>
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
            <Download isSoftware={true}></Download>
            <div className="h20"></div>
            <Footer isSoftware={true} index={5} history={this.props.history}></Footer>
            <ScrollTop></ScrollTop>
          </div>
        )
    }
}