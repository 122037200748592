import { Component } from 'react';
import { Image } from "antd";
import './mobileFooter.css';
import AppDownloadImg from '../../static/footer/APP_download_er_code.png'
import officialDownloadImg from '../../static/footer/official_accounts_er_code.png'
import telIcon from '../../static/footer/footer_tel_icon.png'
import homeIcon from '../../static/footer/footer_home_icon.png'
import positionIcon from '../../static/footer/footer_position_icon.png'
import footer2TopBlockIcon1 from '../../static/footer/footer2_top_block_icon1.png'
import footer2TopBlockIcon2 from '../../static/footer/footer2_top_block_icon2.png'
import footer2TopBlockIcon3 from '../../static/footer/footer2_top_block_icon3.png'
import footer2TopBlockIcon4 from '../../static/footer/footer2_top_block_icon4.png'
import footer2TopBlockIcon5 from '../../static/footer/footer2_top_block_icon5.png'
import footer2TopBlockIcon6 from '../../static/footer/footer2_top_block_icon6.png'
import footer2TopBlockIcon7 from '../../static/footer/footer2_top_block_icon7.png'
import footer2TopBlockIcon8 from '../../static/footer/footer2_top_block_icon8.png'


export default class MobileFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerDataList:[
        {icon:'',title:'5万',desc:'美业手艺人使用'},
        {icon:'',title:'1万+',desc:'美业门店的选择'},
        {icon:'',title:'300万',desc:'会员消费者'},
        {icon:'',title:'50亿',desc:'年交易额 '},
      ],
      projectList:[
        {title:'服务',itemList:['店铺代运营']},
        {title:'产品',itemList:['剑琅管家','医美医务系统','靓丽美集']},
        {title:'进一步了解我们',itemList:['软件下载','关于我们']},
      ],
      mobileModulesList:[{
        title: '数据安全',
        url: footer2TopBlockIcon1
      },{
        title: '快速访问',
        url: footer2TopBlockIcon2
      },{
        title: '技术过硬',
        url: footer2TopBlockIcon3
      },{
        title: '系统稳定',
        url: footer2TopBlockIcon4
      },{
        title: '智能高效',
        url: footer2TopBlockIcon5
      },{
        title: '快速迭代',
        url: footer2TopBlockIcon6
      },{
        title: '实时安装',
        url: footer2TopBlockIcon7
      },{
        title: '专属客服',
        url: footer2TopBlockIcon8
      }],
    }
  }
    
  // 跳转下载页面
  goSoftware() {
    window.open('https://download.round-table-union.com/download/rtb.html')
  }
  render() {
    let {footerDataList,projectList,mobileModulesList} = this.state;
    return (<div className='mobile_footer_page flex_column_center'>
      <div className='mobile_footer_download_card'>
        <div className='mobile_footer_download_title'>剑琅管家</div>
        <div className='mobile_footer_download_title_info'>
          <div className='mobile_footer_download_title_desc'>开启您的线上数字化门店管理</div>
          <div className='mobile_footer_download_btn tc' onClick={this.goSoftware.bind(this)}>立即下载</div>
        </div>
        <div className='mobile_footer_download_item_wrap'>
          {
            footerDataList.map((item,index)=>{
              return <div className='mobile_footer_download_item' key={index}>
                <Image className='mobile_footer_download_item_icon' preview={false} src={item.icon} />
                <div className='mobile_footer_download_item_content'>
                  <div className='mobile_footer_download_item_title fb'>{item.title}</div>
                  <div className='mobile_footer_download_item_desc'>{item.desc}</div>  
                </div>
                </div>
            })
          }
        </div>
      </div>
      <div className='footer_gap'></div>
      <div className='mobile_footer_postcard flex_column_center'>
        <div className='mobile_footer_postcard_er_code'>
          <div className='mobile_footer_postcard_er_code_wrap flex_column_center'><Image width={'3rem'} height={'3rem'} src={AppDownloadImg} preview={false} /><div className='mt20'>APP下载</div></div>
          <div className='mobile_footer_postcard_er_code_wrap flex_column_center'><Image  width={'3rem'} height={'3rem'} src={officialDownloadImg} preview={false} /><div className='mt20'>关注公众号</div></div>
        </div>
        <div className='mobile_footer_postcard_tel mt30'><Image className='mobile_footer_postcard_icon' width={'0.5rem'} height={'0.5rem'} src={telIcon} preview={false} /><div className='ml15'>联系我们 ：021-6331 6099</div></div>
        <div className='mobile_footer_postcard_com mt10'><Image className='mobile_footer_postcard_icon' width={'0.5rem'} height={'0.5rem'}  src={homeIcon} preview={false} /><div className='ml15'>上海集勇信息科技有限公司</div></div>
        <div className='mobile_footer_postcard_add mt10'><Image className='mobile_footer_postcard_icon' width={'0.5rem'} height={'0.5rem'}  src={positionIcon} preview={false} /><div className='ml15'>上海市黄浦区淮海中路222号力宝广场13楼</div></div>
        <div className='mobile_footer_postcard_list'>
          {
            projectList.map((item,index)=>{
              return(
                <div className='mobile_footer_postcard_list_item' key={index}>
                  <div className='mobile_footer_postcard_list_item_title'>{item.title}</div>
                  <div className='mobile_footer_postcard_list_item_content_wrap'>
                    {
                      item.itemList.map((item1,index1)=>{
                        return <div className='mobile_footer_postcard_list_item_content' key={index1}>{item1}</div>
                      })
                    }
                  </div>
                </div>
              )
            }) 
          }
        </div>

        <div className='mobile_footer_modules'>
          {
            mobileModulesList.map((item,index)=>{
              return <div className='mobile_footer_modules_item' key={index}>
                <Image className='mobile_footer_modules_item_icon' preview={false} src={item.url} />
                <div className='mobile_footer_modules_item_title'>{item.title}</div>
              </div>
            })
          }
        </div>
        <div className='mobile_footer_copyright_line'></div>
        <div className='mobile_footer_copyright'>
          <div className='mobile_footer_copyright_title ml40'>Copyright © 2017-2022 Inc.</div>
          <div className='mobile_footer_copyright_number ml40'>沪ICP备17056175号-1</div>
        </div>
      </div>
    </div>)
  }
}